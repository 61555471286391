import type msal from "@azure/msal-browser";
import { z } from "zod";

const authEnvSchema = z.object({
  aadClientId: z.string({
    required_error:
      'The environment variable "NEXT_PUBLIC_AAD_CLIENT_ID" is missing',
  }),
  aadName: z.string({
    required_error:
      'The environment variable "NEXT_PUBLIC_AAD_NAME" is missing',
  }),
  policy: z.string({
    required_error:
      'The environment variable "NEXT_PUBLIC_SIGN_IN_POLICY" is missing',
  }),
});
const { aadClientId, aadName, policy } = authEnvSchema.parse({
  aadClientId: process.env.NEXT_PUBLIC_AAD_CLIENT_ID,
  aadName: process.env.NEXT_PUBLIC_AAD_NAME,
  policy: process.env.NEXT_PUBLIC_SIGN_IN_POLICY,
});

function getBaseURI() {
  if (typeof window === "undefined") return "";
  return `${window.location.origin}/`;
}

export const AZURE_ROPC_TOKEN_ENDPOINT = `https://${aadName}.b2clogin.com/${aadName}.onmicrosoft.com/B2C_1A_ropc_auth/oauth2/v2.0/token`;
export const AZURE_ROPC_USERNAME_PASSWORD_INVALID_CODE = "AADB2C90225";

export const baseURI = getBaseURI();

export const msalConfig: msal.Configuration = {
  auth: {
    clientId: aadClientId,
    authority: `https://${aadName}.b2clogin.com/${aadName}.onmicrosoft.com/${policy}`,
    knownAuthorities: [`${aadName}.b2clogin.com`],
    redirectUri: `${baseURI}oidc-response`,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ["openid", "profile"],
};

export const tokenRequest: msal.SilentRequest = {
  scopes: [
    `https://${aadName}.onmicrosoft.com/api/tgg.admin`,
    `https://${aadName}.onmicrosoft.com/api/tgg.read`,
  ],
  forceRefresh: false,
};
