/* eslint-disable max-lines */
import { AI_COMMENTARY_TOPICS } from "store/reducers/reporting-template/configureExecutiveSummary";

export type TemplateModuleData = {
  id: string;
  reportTemplate: {
    id: string;
    name: string;
    version: string;
    auditInfo: AuditInfo;
  };
  auditInfo: AuditInfo;
  reportModule: {
    id: string;
    name: string;
    description: string;
    code: string;
    displayOrder: number;
    configurable: boolean;
    isDefault: boolean;
    auditInfo: AuditInfo;
    isCustom: boolean;
  };
  config:
    | Array<{
        id: string;
        name: string;
        settings: {
          mode: string;
          glMode: string;
          includeYTD: boolean;
          addCustomTable: boolean;
          includeDescription: boolean;
          tableConfiguration: unknown;
          includePercentageYTD: boolean;
          includeTotalPercentage: boolean;
        };
        reportType: string;
        description: string;
        arApSettings: {
          config: Array<{
            key: string;
            config: {
              criteria: {
                showAllowance?: boolean;
                tableDisplaySelection: string;
                customValue?: number;
              };
              schedules: Array<{ to?: number; from: number }>;
              transactionLevelEnabled: boolean;
            };
          }>;
        };
      }>
    | {
        sections: Array<Section>;
      };
};

export type ReportModuleData = {
  id: string;
  period: string;
  auditInfo: AuditInfo;
  reportModuleConfig: {
    id: string;
    reportTemplate: {
      id: string;
      name: string;
      version: string;
      auditInfo: AuditInfo;
    };
    auditInfo: AuditInfo;
    reportModule: {
      id: string;
      name: string;
      description: string;
      code: string;
      displayOrder: number;
      configurable: boolean;
      isDefault: boolean;
      auditInfo: AuditInfo;
      client: unknown;
      isCustom: boolean;
    };
    config: unknown;
  };
  config: unknown;
};

type AuditInfo = {
  createdAt: string;
  createdBy: string | null;
  updatedAt: string;
  updatedBy: string | null;
};

type Section = {
  id: string;
  pages: Page[];
  title: string;
  displayOrder: number;
  landscapePages: any[];
};

export enum WidgetType {
  TITLE = "title",
  TEXT = "text",
  IMAGE = "image",
  KPI = "kpi",
  DFM = "dfm",
  GOAL = "goal",
  PROFIT_RECOMMENDATION = "profitRecommendation",
  TBL = "tbl",
  CHARTS = "charts",
  PDF = "pdf",
  DATA_TABLE = "data-table",
  CUSTOM_TABLE = "custom-table",
  SALES_BY_CUSTOMER = "sales-by-customer",
  AI_COMMENTARY = "ai-commentary",
}

export type Page = {
  id: string;
  title?: string;
  widgets: Widget[];
  isLandscape: boolean;
  displayOrder: number;
  isDFMClonePage: boolean;
};

type AiWidget = Widget & {
  type: WidgetType.AI_COMMENTARY;
  data: {
    topic: AI_COMMENTARY_TOPICS;
  };
};
export const isAiWidget = (widget?: Widget): widget is AiWidget => {
  return widget?.type === WidgetType.AI_COMMENTARY && !!widget.data?.topic;
};

export type Widget = {
  dfmStatementType?: string | null;
  allPageUniqueId?: unknown;
  id: string;
  data?: Data;
  type: WidgetType;
  title?: TitleClass | string;
  metadata?: Metadata;
  pageName?: string;
  properties?: WidgetProperties | null;
  displayOrder: number;
  chartType?: string;
  name?: string;
  value?: string;
  iconName?: string;
  iconType?: string;
  kpiId?: string;
  kpiType?: string;
  axisSetting?: AxisSetting;
  description?: Description;
  addDataTable?: boolean;
  outputFormat?: OutputFormat;
  trailingPeriod?: {
    value: string;
  };
  chartFormatting?: ChartFormatting;
  disableDataLabels?: boolean;
  descriptionMonthly?: Description;
  isDescriptionChanged?: boolean;
  isAICommentaryEnabled?: boolean;
};

export type AxisSetting = {
  max: null;
  min: null;
  intervals: null;
  showDecimalPlaces: boolean;
  numberOfDecimalPlaces: null;
};

type ChartFormatting = {
  position: string;
  chartType: string;
  targetLine: TargetLine;
  targetRange: TargetRange;
  selectedFormat: string;
};

type TargetLine = {
  lineColor: string;
  targetValue: string;
};

type TargetRange = {
  lowRangeValue: string;
  highRangeValue: string;
};

type Data = {
  id: string;
  hideHeader?: boolean;
  settings?: Settings;
  topic: AI_COMMENTARY_TOPICS;
  value: string;
};

type Settings = {
  period: string;
  isPercentage: boolean;
  topXCustomer: number;
  customXCustomer: null;
  isMonthlyCommentary: boolean;
};

export type Description = {
  name: string;
  type: string;
  value: string;
  properties: DescriptionProperties;
};

type DescriptionProperties = {
  isBold: boolean;
  fontSize: FontSizeClass;
  isItalic: boolean;
  alignment: string;
  isUnderline: boolean;
};

type FontSizeClass = {
  label: number;
  value: string;
};

type Metadata = {
  descriptionMonthly: Description | null;
  input?: InputElement[] | PurpleInput;
  title?: string;
  isDescriptionChanged?: boolean;
  intervalType?: string;
  trailingPeriods?: number;
  summary?: string;
  chartType?: string;
  formatting?: Formatting;
  description?: Description;
  addDataTable?: boolean;
  leftAxisSetting?: AxisSetting;
  disableDataLabels?: boolean;
  rightAxisSetting?: AxisSetting;
  conditionFormatting?: boolean;
  period?: string;
  addComparableChart?: boolean;
  aiCommentaryEnabled?: boolean;
  aiCommentaryDescription?: Description;
};

type Formatting = {
  targetLine: TargetLine;
  selectedFormat: string;
  targetRange?: TargetRange;
};

export type InputElement = {
  title: string;
  dataSourceId: string;
  dataSourceType: string;
  reportTypeCode: string;
  position?: string;
  chartType?: string;
  enableDataLabels?: boolean;
  inputFormat?: string;
};

type PurpleInput = {
  title: string;
  inputFormat: string;
  dataSourceId: string;
  dataSourceType: string;
  reportTypeCode: string;
};

type OutputFormat = {
  type: string;
  decimals: number;
};

type WidgetProperties = {
  alignment?: string;
  fontSize?: FontSizeClass | string;
  isBold?: boolean;
  isItalic?: boolean;
  isUnderline?: boolean;
  includeInToc?: boolean;
};

type TitleClass = {
  name: string;
  type: string;
  value: string;
  properties: TitleProperties;
};

type TitleProperties = {
  isBold: boolean;
  fontSize: string;
  alignment: string;
  includeInToc: boolean;
};

export interface PieChartData {
  period: string;
  categories: string[];
  series: number[];
}

export interface ChartDataComparison {
  currentData: PieChartData;
  comparableData: PieChartData;
}

export interface StackedChartData {
  trailingSeries: {
    name: string;
    data: number[];
  }[];
  categories: string[];
  max: number;
  outputFormat: string;
}
