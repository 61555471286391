import ReportsServices from "@services/APIs/Reports";
import { useQuery } from "react-query";

export function useClientTemplatePackages(
  clientId: string,
  reportTemplateId: string,
  requireFinancialPackages: boolean = false,
  year?: number,
  month?: number
) {
  const callValidated = requireFinancialPackages
    ? !!reportTemplateId && !!clientId && !!year && !!month
    : !!reportTemplateId && !!clientId;

  const {
    data: templates,
    refetch: refetchTemplates,
    isFetched: hasFetchedTemplates,
    isLoading: isLoadingTemplates,
  } = useQuery(
    [
      `/clients/${clientId}/report-templates/${reportTemplateId}/modules`,
      {
        clientId,
        reportTemplateId,
        mode: requireFinancialPackages
          ? "financial-package"
          : "template-builder",
        year,
        month,
      },
    ],
    ReportsServices.getReportModules,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: false,
      enabled: !!callValidated,
    }
  );

  return {
    templates,
    isFetchedTemplates: hasFetchedTemplates,
    refetchTemplates: () => refetchTemplates(),
    isLoadingTemplates,
  };
}
