/* eslint-disable max-lines */
import {
  createAsyncThunk,
  createSlice,
  type PayloadAction,
} from "@reduxjs/toolkit";
import AuthServices from "@services/AuthServices";
import { DataDiagnosticsApi } from "@tgg_accounting/hub-node-api";
import {
  DiagnosticClientSummaryDisplayModel,
  DiagnosticClientUpdateResultStatusDtoStatusEnum,
  DiagnosticRunDto,
} from "@tgg_accounting/hub-node-api/dist/models";
import { sortingType } from "@utils/constant";
import axios from "axios";
import { toast } from "react-toastify";

export enum DashboardTimeRangeStatus {
  ARCHIVED = "Achieved",
  DECLINED = "Declined",
  IN_PROGRESS = "In Progress",
  AWAITING_REVIEW = "Client Review",
}

export enum DashboardTimeRange {
  LAST_MONTH = "LAST_MONTH",
  LAST_3_MONTHS = "LAST_3_MONTHS",
  LAST_6_MONTHS = "LAST_6_MONTHS",
  LAST_YEAR = "LAST_YEAR",
}

export const DashboardTabNames: Record<DashboardTimeRange, string> = {
  [DashboardTimeRange.LAST_MONTH]: "Last Month",
  [DashboardTimeRange.LAST_3_MONTHS]: "Last 3 Months",
  [DashboardTimeRange.LAST_6_MONTHS]: "Last 6 Months",
  [DashboardTimeRange.LAST_YEAR]: "Last Year",
};

export const DashboardTabIdsByName: Record<string, DashboardTimeRange> = {
  "Last Month": DashboardTimeRange.LAST_MONTH,
  "Last 3 Months": DashboardTimeRange.LAST_3_MONTHS,
  "Last 6 Months": DashboardTimeRange.LAST_6_MONTHS,
  "Last Year": DashboardTimeRange.LAST_YEAR,
};

const initialState = {
  diagnostics: [] as DiagnosticV2[],
  diagnosticsTimeline: {
    [DashboardTimeRange.LAST_MONTH]: [] as DiagnosticTimelineStatus[],
    [DashboardTimeRange.LAST_3_MONTHS]: [] as DiagnosticTimelineStatus[],
    [DashboardTimeRange.LAST_6_MONTHS]: [] as DiagnosticTimelineStatus[],
    [DashboardTimeRange.LAST_YEAR]: [] as DiagnosticTimelineStatus[],
  } as DiagnosticsTimelineData,
  totalPages: null as null | number,
  totalRecords: null as null | number,
  isFetchingDiagnostics: true,
  isRunningDiagnostics: false,
  isPublishingDiagnostics: true,
  isDataSynced: true,
  queryParams: {
    search: null as null | string,
    status: null as null | DiagnosticClientUpdateResultStatusDtoStatusEnum,
    year: null as null | number,
    month: null as null | number,
    period: 0,
    skip: 0,
    categoryId: null as null | string,
    take: 10,
    sortBy: "month",
    sortDir: sortingType.descending,
  },
  summary: null as null | DiagnosticClientSummaryDisplayModel,
  isFetchingSummary: true,
  isFetchingDiagnosticsTimeline: true,
  categories: {} as Record<
    string,
    {
      id: string;
      categoryName: string;
      diagnostics: Array<{ id: string; message: string }>;
    }
  >,
  activeTab: DashboardTimeRange.LAST_MONTH,
  selectedCategory: null as null | {
    label: string;
    value: string;
  },
  isFetchingCategories: true,
  modal: {
    isOpen: false,
    content: null as DiagnosticV2 | null,
  },
};

const getApi = () => {
  const accessToken = AuthServices.getAccessToken();
  return new DataDiagnosticsApi(
    {
      basePath: process.env.NEXT_PUBLIC_TGG_API_BASE_URI,
      baseOptions: {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    },
    process.env.NEXT_PUBLIC_TGG_API_BASE_URI
  );
};

export type DiagnosticV2 = {
  id: string;
  diagnostic: {
    id: string;
    name: string;
    description: string;
    category: {
      id: string;
      name: string;
    };
  };
  year: number;
  month: number;
  message: string;
  status: DiagnosticClientUpdateResultStatusDtoStatusEnum;
};

type DiagnosticTimelineStatus = {
  status: DashboardTimeRangeStatus;
  count: number;
};

type DiagnosticsTimelineData = Record<
  DashboardTimeRange,
  DiagnosticTimelineStatus[]
>;

export const deleteDiagnostic = createAsyncThunk(
  "dataDiagnostics/delete",
  async (
    params: {
      clientId: string;
      diagnosticId: string;
      accountId: string;
    },
    thunkAPI
  ) => {
    const api = getApi();
    const response =
      await api.dataDiagnosticsClientControllerDeleteDiagnosticResult(
        params.clientId,
        params.diagnosticId
      );
    const success = response.data.value;
    if (success) {
      return { id: params.diagnosticId };
    }
    return thunkAPI.rejectWithValue(null);
  }
);

export const runDiagnostics = createAsyncThunk(
  "dataDiagnostics/run",
  async (params: { clientId: string; accountId: string }) => {
    const api = getApi();
    const diagnosticRunDto: DiagnosticRunDto = {
      period: undefined,
    }; // Populate this with necessary data if needed

    // Make the API call to run diagnostics
    await api.dataDiagnosticsClientControllerRunDiagnostics(
      diagnosticRunDto,
      params.clientId
    );
  }
);

export const getDataDiagnostics = createAsyncThunk(
  "dataDiagnostics/get",
  async (params: {
    clientId: string;
    skip?: number;
    accountId?: string;
    search?: string | null;
    year?: number | null;
    month?: number | null;
    status?: DiagnosticClientUpdateResultStatusDtoStatusEnum | null;
    categoryId?: string | null;
    excludeDrafts?: boolean;
    take?: number;
    sortBy?: string;
    sortDir?: string;
  }) => {
    const api = getApi();

    // Construct the request parameters
    const queryParams = {
      clientId: params.clientId,
      skip: params.skip,
      take: params.take,
      search: params.search || undefined,
      year: params.year || undefined,
      month: params.month || undefined,
      status: params.status || undefined,
      categoryId: params.categoryId || undefined,
      sortBy: params.sortBy || undefined,
      sortDir: params.sortDir || undefined,
    };

    // Make the API call to fetch diagnostics
    try {
      const response = await api.dataDiagnosticsClientControllerListDiagnostics(
        queryParams.clientId,
        queryParams.month,
        queryParams.year,
        queryParams.status,
        queryParams.categoryId,
        queryParams.sortDir,
        queryParams.sortBy,
        queryParams.search,
        queryParams.take,
        queryParams.skip
      );

      return {
        items: response.data.items as DiagnosticV2[],
        totalPages: response.data.totalPages as number,
        totalRecords: response.data.totalRecords as number,
      };
    } catch (error) {
      if (axios.isAxiosError(error) && error?.response?.status === 404) {
        return {
          items: [],
          totalPages: 1,
          totalRecords: 0,
        };
      }
    }
  }
);

export const getDataDiagnosticsTimeline = createAsyncThunk(
  "dataDiagnostics/getTimeline",
  async (params: { clientId: string; accountId: string }) => {
    const api = getApi();
    const diagnosticsTimeline = {} as DiagnosticsTimelineData;
    await Promise.all(
      Object.values(DashboardTimeRange).map(async () => {
        for (const period of Object.values(DashboardTimeRange)) {
          const queryParams = {
            clientId: params.clientId,
            period,
          };

          try {
            const response =
              await api.dataDiagnosticsClientControllerDashboardTimeline(
                queryParams.clientId,
                queryParams.period
              );

            response.data;

            diagnosticsTimeline[period] = response.data;
          } catch (error) {
            if (axios.isAxiosError(error)) {
              diagnosticsTimeline[period] = [];
            }
          }
        }
      })
    );

    return diagnosticsTimeline;
  }
);

export const checkAisSync = createAsyncThunk(
  "dataDiagnostics/checkAisSync",
  async (params: {
    clientId: string;
    year: number;
    month: number;
    accountId: string;
  }) => {
    const api = getApi();

    const response = await api.dataDiagnosticsClientControllerCheckAisSync(
      params.clientId,
      params.year,
      params.month
    );

    return response.data;
  }
);

export const transitionDiagnostic = createAsyncThunk(
  "dataDiagnostics/transition",
  async (params: {
    clientId: string;
    diagnosticId: string;
    status: DiagnosticClientUpdateResultStatusDtoStatusEnum;
  }) => {
    const api = getApi();
    await api.dataDiagnosticsClientControllerUpdateDiagnosticResultStatus(
      { status: params.status },
      params.clientId,
      params.diagnosticId
    );
  }
);

export const getClientDiagnosticsSummary = createAsyncThunk(
  "dataDiagnostics/getSummary",
  async (params: {
    clientId: string;
    accountId: string;
    month?: number;
    year?: number;
    diagnosticId?: string;
    categoryId?: string;
  }) => {
    const api = getApi();

    const queryParams: Record<string, any> = {};

    if (params.month !== undefined && params.month !== null) {
      queryParams["month"] = params.month;
    }

    if (params.year !== undefined && params.year !== null) {
      queryParams["year"] = params.year;
    }

    if (params.diagnosticId !== undefined && params.diagnosticId !== null) {
      queryParams["diagnosticId"] = params.diagnosticId;
    }

    if (params.categoryId !== undefined && params.categoryId !== null) {
      queryParams["categoryId"] = params.categoryId;
    }

    const response = await api.dataDiagnosticsClientControllerListSummary(
      params.clientId,
      queryParams["month"],
      queryParams["year"],
      queryParams["diagnosticId"],
      queryParams["categoryId"]
    );

    return response.data;
  }
);

export const dataDiagnosticsSlice = createSlice({
  name: "dataDiagnostics",
  initialState,
  reducers: {
    updateQueryParams: (
      state,
      action: PayloadAction<Partial<(typeof state)["queryParams"]>>
    ) => {
      state.queryParams = { ...state.queryParams, ...action.payload };
    },
    setModalContent: (state, action: PayloadAction<DiagnosticV2 | null>) => {
      state.modal.content = action.payload;
    },
    setModalOpen: (state, action: PayloadAction<boolean>) => {
      state.modal.isOpen = action.payload;
    },
    setSelectedCategory: (
      state,
      action: PayloadAction<{ label: string; value: string } | null>
    ) => {
      state.selectedCategory = action.payload;
    },
    setActiveTab: (state, action: PayloadAction<DashboardTimeRange>) => {
      state.activeTab = action.payload;
    },
  },
  extraReducers(builder) {
    // Run diagnostics
    builder.addCase(runDiagnostics.fulfilled, (state) => {
      state.isRunningDiagnostics = false;
    });
    builder.addCase(runDiagnostics.pending, (state) => {
      state.isRunningDiagnostics = true;
    });
    builder.addCase(runDiagnostics.rejected, (state) => {
      state.isRunningDiagnostics = false;
    });

    // Fetch data diagnostics
    builder.addCase(getDataDiagnostics.fulfilled, (state, action) => {
      state.diagnostics = action.payload?.items ?? [];
      state.totalPages = action.payload?.totalPages ?? 1;
      state.totalRecords = action.payload?.totalRecords ?? 0;
      state.isFetchingDiagnostics = false;

      state.categories = {};
      for (const item of state.diagnostics) {
        if (item.diagnostic.category.id in state.categories) {
          state.categories[item.diagnostic.category.id].diagnostics.push({
            id: item.id,
            message: item.message,
          });
        } else {
          state.categories[item.diagnostic.category.id] = {
            id: item.diagnostic.category.id,
            categoryName: item.diagnostic.category.name,
            diagnostics: [
              {
                id: item.id,
                message: item.message,
              },
            ],
          };
        }
      }
    });
    builder.addCase(getDataDiagnostics.pending, (state) => {
      state.isFetchingDiagnostics = true;
    });
    builder.addCase(getDataDiagnostics.rejected, (state) => {
      state.isFetchingDiagnostics = false;
    });

    // Transition diagnostic
    builder.addCase(transitionDiagnostic.fulfilled, (state, action) => {
      state.isPublishingDiagnostics = false;
      const { diagnosticId, status } = action.meta.arg;

      const diagnostic = state.diagnostics.find((d) => d.id === diagnosticId);
      if (diagnostic) diagnostic.status = status;
    });
    builder.addCase(transitionDiagnostic.pending, (state) => {
      state.isPublishingDiagnostics = true;
    });
    builder.addCase(transitionDiagnostic.rejected, (state) => {
      state.isPublishingDiagnostics = false;
    });

    // Delete diagnostic
    builder.addCase(deleteDiagnostic.fulfilled, (state, action) => {
      state.isFetchingDiagnostics = false;
      state.diagnostics = state.diagnostics.filter(
        (diagnostic) => diagnostic.id !== action.payload.id
      );
    });
    builder.addCase(deleteDiagnostic.pending, (state) => {
      state.isFetchingDiagnostics = true;
    });
    builder.addCase(deleteDiagnostic.rejected, (state) => {
      state.isFetchingDiagnostics = false;
      toast.error(
        "We could not delete this diagnostic. Please try again later."
      );
    });
    // Check if data is synced
    builder.addCase(checkAisSync.fulfilled, (state, action) => {
      state.isDataSynced = action.payload.isSynced;
    });
    builder.addCase(checkAisSync.pending, (state) => {
      state.isDataSynced = false;
    });
    builder.addCase(checkAisSync.rejected, (state) => {
      state.isDataSynced = false;
    });

    // Fetch client diagnostics summary
    builder.addCase(getClientDiagnosticsSummary.fulfilled, (state, action) => {
      state.summary = action.payload;
      state.isFetchingSummary = false;
    });
    builder.addCase(getClientDiagnosticsSummary.pending, (state) => {
      state.isFetchingSummary = true;
    });
    builder.addCase(getClientDiagnosticsSummary.rejected, (state) => {
      state.isFetchingSummary = false;
    });

    // Fetch Diagnostic Timeline getDataDiagnosticsTimeline
    builder.addCase(getDataDiagnosticsTimeline.fulfilled, (state, action) => {
      state.diagnosticsTimeline = action.payload;

      state.isFetchingDiagnosticsTimeline = false;
    });
    builder.addCase(getDataDiagnosticsTimeline.pending, (state) => {
      state.isFetchingDiagnosticsTimeline = true;
    });
    builder.addCase(getDataDiagnosticsTimeline.rejected, (state) => {
      state.isFetchingDiagnosticsTimeline = false;
    });
  },
});

export const dataDiagnosticsActions = dataDiagnosticsSlice.actions;
