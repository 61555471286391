import "../i18n";
import "../styles/styles.scss";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import "react-toastify/dist/ReactToastify.css";
import "styles/utils/progressBar.css";
import "styles/global.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { MsalProvider } from "@azure/msal-react";
import AccountProvider from "@context/account";
import AgencyProvider from "@context/agency";
import AuthProvider from "@context/auth";
import ClientProvider from "@context/client";
import FormulaBuilderProvider from "@context/formulaBuilder";
import ReportProvider from "@context/report";
import SeedDataProvider from "@context/seedData";
import ThemeProvider from "@context/theme";
import * as FullStory from "@fullstory/browser";
import { getCurrentScope } from "@sentry/nextjs";
import AuthServices, { tggApplication } from "@services/AuthServices";
import { initSplitSdk } from "@splitsoftware/splitio-redux";
import Chameleon from "components-v2/atoms/integrations/Chameleon";
import Heap from "components-v2/atoms/integrations/Heap";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import { AppProps } from "next/app";
import React, { FC, useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import StoreProvider from "store/provider";
import { wrapper } from "store/store";
import { z } from "zod";
import { AppCacheProvider } from "@mui/material-nextjs/v15-pagesRouter";
import { setProgressBar } from "../utils/setProgressBar";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import Head from "next/head";
import theme, { roboto } from "../styles/theme";
import { CssBaseline } from "@mui/material";

setProgressBar();

// eslint-disable-next-line react/destructuring-assignment
const MyApp: FC<AppProps> = ({ Component, ...rest }) => {
  const queryClient = new QueryClient();
  const { store, props } = wrapper.useWrappedStore(rest);

  useEffect(() => {
    const activeUser = AuthServices.getDecodedAccessToken();
    store.dispatch(
      initSplitSdk({
        config: {
          core: {
            authorizationKey: z
              .string({
                required_error:
                  'Missing environment variable "NEXT_PUBLIC_SPLIT_IO_KEY"',
              })
              .parse(process.env.NEXT_PUBLIC_SPLIT_IO_KEY),
            key: activeUser?.tggUserId,
          },
        },
      })
    );

    if (typeof window !== "undefined" && process.env.NEXT_PUBLIC_VERCEL_ENV) {
      LogRocket.init("mfaolg/hub");

      setupLogRocketReact(LogRocket);

      if (activeUser) {
        LogRocket.identify(activeUser.tggUserId, {
          name: `${activeUser.given_name} ${activeUser.family_name}`,
        });
      }

      LogRocket.getSessionURL((sessionURL) => {
        const scope = getCurrentScope();
        scope.setExtra("sessionURL", sessionURL);
      });
    }
  }, []);

  useEffect(() => {
    const fsOrgId = process.env.NEXT_PUBLIC_TGG_UAT_FS_ORG;
    if (fsOrgId) {
      FullStory.init({ orgId: fsOrgId, namespace: "FS" });
    }
  }, []);

  return (
    <React.Fragment>
      <Chameleon />
      <Heap />
      <AppCacheProvider {...props}>
        <Head>
          <meta name="viewport" content="initial-scale=1, width=device-width" />
        </Head>
        <StoreProvider store={store}>
          <MsalProvider instance={tggApplication}>
            <QueryClientProvider client={queryClient}>
              <ThemeProvider>
                <AgencyProvider>
                  <AuthProvider>
                    <AccountProvider>
                      <SeedDataProvider>
                        <ClientProvider>
                          <ReportProvider>
                            <FormulaBuilderProvider>
                              <MuiThemeProvider theme={theme}>
                                <CssBaseline />
                                <main className={roboto.variable}>
                                  <Component {...props.pageProps} />
                                </main>
                              </MuiThemeProvider>
                              <ToastContainer
                                position="top-center"
                                autoClose={3000}
                                hideProgressBar
                                newestOnTop
                                closeOnClick={false}
                                rtl={false}
                                pauseOnFocusLoss
                                draggable={false}
                                pauseOnHover={false}
                                theme="light"
                              />
                            </FormulaBuilderProvider>
                          </ReportProvider>
                        </ClientProvider>
                      </SeedDataProvider>
                    </AccountProvider>
                  </AuthProvider>
                </AgencyProvider>
              </ThemeProvider>
            </QueryClientProvider>
          </MsalProvider>
        </StoreProvider>
      </AppCacheProvider>
    </React.Fragment>
  );
};

export default MyApp;
