import { Roboto } from "next/font/google";
import { createTheme } from "@mui/material/styles";
import LinkBehaviour from "../utils/LinkBehaviour";

export const roboto = Roboto({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
  variable: "--font-roboto",
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#4C6FFF",
    },
    info: {
      light: "#EDF2F7",
      main: "#0288d1",
    },
    text: {
      primary: "#143049",
    },
  },
  typography: {
    fontFamily: "var(--font-roboto)",
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehaviour,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehaviour,
      },
    },
  },
});

export default theme;
