import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./public/locales/en";

i18n.use(initReactI18next).init({
  resources: { en },
  fallbackLng: "en",
});

export function getCurrentLanguage() {
  return i18n.language || "en";
}

export default i18n;
