import * as msal from "@azure/msal-browser";
import { UsersApi } from "@tgg_accounting/tenant-api";
import { localKeys } from "@utils/constant/index";
import {
  AZURE_ROPC_TOKEN_ENDPOINT,
  AZURE_ROPC_USERNAME_PASSWORD_INVALID_CODE,
  loginRequest,
  msalConfig,
} from "@utils/constant/msalConfig";
import { clientCookieManager } from "@utils/cookies/client";
import axios from "axios";
import { createRemoteJWKSet, jwtVerify } from "jose";
import jwtDecode from "jwt-decode";
import { v4 as uuidv4 } from "uuid";

import i18n from "../../i18n";

export const tggApplication = new msal.PublicClientApplication(msalConfig);

type DecodedJwt = {
  exp: number;
  nbf: number;
  ver: string;
  iss: string;
  sub: string;
  aud: string;
  acr: string;
  nonce: string;
  iat: number;
  auth_time: number;
  tid: string;
  tggUserId: string;
  name: string;
  given_name: string;
  family_name: string;
  at_hash?: string;
};

type AzureROPCTokenResponse = {
  access_token: string;
  token_type: string;
  expires_in: string;
  id_token: string;
};

type AzureROPCError = {
  error: string;
  error_description: string;
};

type ROPCLoginSuccessResponse = {
  status: "success";
  token: string;
  userId: string;
};

type ROPCLoginErrorResponse = {
  status: "error";
  error: string;
};

type ROPCLoginResponse = ROPCLoginSuccessResponse | ROPCLoginErrorResponse;

class AuthServices {
  logInWithRedirect = async (
    logoURL: string | null | undefined,
    loginHint = "",
    agencyId = ""
  ) => {
    sessionStorage.clear();
    this.setSession(null);
    for (const key of Object.keys(localStorage)) {
      if (localStorage.getItem(key)?.includes("b2clogin.com")) {
        localStorage.removeItem(key);
      }
    }
    return tggApplication.loginRedirect({
      scopes: loginRequest.scopes,
      extraQueryParameters: {
        tenantLogo:
          logoURL || `${window.location.origin}/assets/images/logo2.webp`,
        login_hint: loginHint,
        agencyId,
      },
    }) as Promise<never>;
  };

  logInWithROPCFlow = async (
    emailAddress: string,
    password: string
  ): Promise<ROPCLoginResponse> => {
    const uniqueTokenForSession = uuidv4();
    const clientId = msalConfig.auth.clientId;

    try {
      const { data: tokenResponse } = await axios.post<AzureROPCTokenResponse>(
        `${AZURE_ROPC_TOKEN_ENDPOINT}?nonce=${uniqueTokenForSession}`,
        {
          username: emailAddress,
          password,
          grant_type: "password",
          scope: `openid ${clientId}`,
          client_id: clientId,
          response_type: "id_token",
        },
        { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
      );

      const decodedToken = jwtDecode<DecodedJwt>(tokenResponse.id_token);

      if (decodedToken.nonce === uniqueTokenForSession) {
        return {
          status: "success",
          token: tokenResponse.id_token,
          userId: decodedToken.tggUserId,
        };
      }
    } catch (error) {
      if (
        axios.isAxiosError<AzureROPCError>(error) &&
        error.response?.status === 400 &&
        error.response?.data
      ) {
        const azureResponse = error.response.data;

        if (
          azureResponse.error_description.includes(
            AZURE_ROPC_USERNAME_PASSWORD_INVALID_CODE
          )
        ) {
          return {
            status: "error",
            error: i18n.t(
              "authentication.login.errors.invalid_email_or_password"
            ),
          };
        }
      } else {
        throw error;
      }
    }

    return {
      status: "error",
      error: i18n.t("authentication.login.errors.generic"),
    };
  };

  logOut = async () => {
    sessionStorage.clear();
    this.setSession(null);

    clientCookieManager.deleteCookie("hub.client.id");
    clientCookieManager.deleteCookie("hub.client.slug");
    clientCookieManager.deleteCookie("hub.client.jwt");
    clientCookieManager.deleteCookie("hub.agency.id");
    clientCookieManager.deleteCookie("hub.agency.slug");

    await tggApplication.logoutRedirect();
  };

  registerWithInviteCode = async (inviteCode: string) => {
    return tggApplication.loginRedirect({
      scopes: loginRequest.scopes,
      extraQueryParameters: {
        inviteCode,
        option: "signup",
      },
    }) as Promise<never>;
  };

  handleAuthentication = () => {
    const accessToken = this.getAccessToken();
    if (!accessToken) {
      return "noAccessToken";
    }
    if (this.isAuthTokenValid(accessToken)) {
      this.setSession(accessToken);
      return "Authenticated";
    }
    this.logOut();
    return "notValidToken";
  };

  setSession = (accessToken: string | null) => {
    if (accessToken) {
      localStorage.setItem(localKeys.tggToken, accessToken);
      sessionStorage.setItem(localKeys.refetchTggToken, "yes");
      clientCookieManager.setCookie("hub.client.jwt", accessToken);
    } else {
      localStorage.removeItem(localKeys.tggToken);
      sessionStorage.removeItem(localKeys.refetchTggToken);
      clientCookieManager.deleteCookie("hub.client.jwt");
    }
  };

  isAuthTokenValid = (accessToken?: string | null): accessToken is string => {
    if (!accessToken) {
      return false;
    }
    const decoded = jwtDecode<DecodedJwt>(accessToken);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn("access token expired");
      return false;
    }
    return true;
  };

  isAboutToExpire = () => {
    const accessToken = this.getAccessToken();
    if (!accessToken) {
      return false;
    }
    const decoded = jwtDecode<DecodedJwt>(accessToken);
    const currentTime = Date.now() / 1000;
    const fiveMin = 5 * 60;
    if (decoded.exp < currentTime + fiveMin) {
      return true;
    }
    return false;
  };

  getAccessToken = () => {
    const accessToken = localStorage.getItem(localKeys.tggToken);
    if (accessToken !== null) {
      clientCookieManager.setCookie("hub.client.jwt", accessToken);
      return accessToken;
    }
    return null;
  };

  getDecodedAccessToken = () => {
    const accessToken = this.getAccessToken();
    const isAuthTokenValid = this.isAuthTokenValid(accessToken);
    if (isAuthTokenValid) {
      const decoded = jwtDecode<DecodedJwt>(accessToken);
      return decoded;
    }
  };

  serverSideValidateToken = async (accessToken: string) => {
    const tenant = process.env.NEXT_PUBLIC_AAD_NAME;
    const policy = process.env.NEXT_PUBLIC_SIGN_IN_POLICY;
    const openidConfigUrl = `https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/${policy}/v2.0/.well-known/openid-configuration`;
    const response = await fetch(openidConfigUrl);
    const { issuer, jwks_uri } = await response.json();
    const JWKS = createRemoteJWKSet(new URL(jwks_uri));
    const { payload, protectedHeader } = await jwtVerify(accessToken, JWKS, {
      issuer,
    });
    return {
      payload,
      protectedHeader,
    };
  };

  isSuperAdmin = async (accessToken: string) => {
    if (typeof window !== "undefined") {
      throw new Error(
        "AuthServices.isSuperAdmin can only be run on the server side."
      );
    }

    try {
      const jwt = await this.serverSideValidateToken(accessToken);
      const userId = jwt.payload.tggUserId as string;
      const tentantUsers = new UsersApi({
        basePath: process.env.NEXT_PUBLIC_TGG_TENANT_API_BASE_URI,
        accessToken,
      });
      const { data } = await tentantUsers.usersControllerGetUserByCriteria(
        userId,
        ["role-group"]
      );
      return data.userRoles.includes("super-admin");
    } catch (error) {
      console.error(error);
      return false;
    }
  };
}

const instance = new AuthServices();

export default instance;
