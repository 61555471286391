/* eslint-disable max-lines */
import { AxisSetting, Page, Widget, WidgetType } from "@context/report/types";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import { months } from ".";

const portraitPageHeight = 29.7; // cm
const portraitPageWidth = 21; // cm
const portraitPageHorizontalPadding = 4; // cm
const portraitPageVerticalPadding = 3; // cm
const landscapePageHeight = 21; // cm
const landscapePageWidth = 29.7; // cm
const landscapePageHorizontalPadding = 3; // cm
const landscapePageVerticalPadding = 4; // cm
const pageGap = 0.396875; // cm
const notesGap = 0.2; // cm

export const pageSizes = {
  portraitPageHeight,
  portraitPageWidth,
  portraitPageHorizontalPadding,
  portraitPageVerticalPadding,
  landscapePageWidth,
  landscapePageHeight,
  landscapePageHorizontalPadding,
  landscapePageVerticalPadding,
  pageGap,
  notesGap,
  portraitPageContentHeight: portraitPageHeight - portraitPageHorizontalPadding, // cm
  landscapePageContentHeight:
    landscapePageHeight - landscapePageHorizontalPadding, // cm
};

enum FontSize {
  H1 = "24",
  H2 = "22",
  H3 = "20",
  H4 = "18",
}

export type FontSizeProperties = {
  label: string | number;
  value: string;
};

export type OpenPage = {
  pageId: string;
  pageIndex: number;
  widgetType: WidgetType;
  widgetId?: string;
};

type WidgetProperties = {
  fontSize?: FontSize | FontSizeProperties;
  isBold?: boolean;
  isItalic?: boolean;
  isUnderline?: boolean;
  includeInToc?: boolean;
  alignment?: string;
};

export enum KPITypeEnum {
  CUSTOM_KPI = "custom-kpi",
  STANDARD_KPI = "std-kpi",
}

type ReportOutputFormat = {
  type: string;
  decimals: number;
};

type ChartTargetLine = {
  lineColor: string;
  targetValue: string;
};

type ChartTargetRange = {
  lowRangeValue: string;
  highRangeValue: string;
};

type KPIChartFormatting = {
  chartType: string;
  position: string;
  targetLine: ChartTargetLine | string | null;
  targetRange: ChartTargetRange | string | null;
  targetProperties: unknown | null;
  selectedFormat: string;
};

export type WidgetData = {
  id: string;
  type: WidgetType;
  kpiId: string;
  title: Widget;
  kpiType: KPITypeEnum;
  axisSettings: AxisSetting;
  description: Widget;
  addDataTable: boolean;
  displayOrder: number;
  outputFormat: ReportOutputFormat;
  trailingPeriod: {
    value: number;
  };
  chartFormatting: KPIChartFormatting;
  disableDataLabels: boolean;
  descriptionMonthly: Widget;
};

export type EnabledWidget = {
  pageId: string;
  widget: WidgetData | Widget;
};

const titleProperties: Partial<WidgetProperties> = {
  fontSize: FontSize.H1,
  isBold: true,
  alignment: "left",
  includeInToc: false,
};

const textProperties = {
  fontSize: { label: 12, value: "12px" },
  isBold: false,
  isItalic: false,
  isUnderline: false,
  alignment: "left",
};

export const dataTableProperties = {
  alignment: "left",
};

export const salesByCustomTableProperties = {
  alignment: "left",
};

const imageProperties = {
  alignment: "left",
};

export const defaultPage = (): Page => ({
  id: uuidv4(),
  title: "",
  displayOrder: 1,
  widgets: [],
  isLandscape: false,
  isDFMClonePage: false,
});

export const defaultTitleWidget: WidgetButton = {
  type: WidgetType.TITLE,
  name: "Title",
  value: "Title",
  properties: titleProperties,
};

export const defaultTextWidget: WidgetButton = {
  type: WidgetType.TEXT,
  name: "Text",
  value: "",
  properties: textProperties,
};

const defaultImageWidget: WidgetButton = {
  type: WidgetType.IMAGE,
  name: "Image",
  value: "",
  properties: imageProperties,
};

const defaultPdfWidget: WidgetButton = {
  type: WidgetType.PDF,
  name: "PDF",
  value: "",
  properties: null,
};

export const defaultKPIChartParameters = {
  chartType: "line",
  position: "Left axis",
  targetLine: "",
  targetProperties: null,
};

export type WidgetButton = {
  type: WidgetType;
  name: string;
  value: string;
  properties: WidgetProperties | null;
  iconName?: string;
  iconType?: string;
};
export const reportWidgets: WidgetButton[] = [
  {
    ...defaultTitleWidget,
    iconType: "heading",
  },
  {
    ...defaultTextWidget,
    iconName: "text",
  },
  {
    ...defaultImageWidget,
    iconName: "picture",
  },
  {
    ...defaultPdfWidget,
    iconName: "pdf",
  },
  {
    type: WidgetType.KPI,
    name: "KPI",
    value: "",
    properties: null,
    iconName: "calculator",
  },
  {
    type: WidgetType.DFM,
    name: "Condensed Financial Statement",
    value: "",
    properties: null,
    iconName: "customize",
  },
  {
    type: WidgetType.GOAL,
    name: "Goal",
    value: "",
    properties: null,
    iconName: "goal",
  },
  {
    type: WidgetType.PROFIT_RECOMMENDATION,
    name: "Profitability Recommendation",
    value: "",
    properties: null,
    iconName: "profit",
  },
  {
    type: WidgetType.TBL,
    name: "Triple Bottom Line",
    value: "",
    properties: null,
    iconName: "tbl",
  },
  {
    type: WidgetType.CHARTS,
    name: "Charts & Graphs",
    value: "",
    properties: null,
    iconName: "charts",
  },
  {
    type: WidgetType.DATA_TABLE,
    name: "Data Table",
    value: "",
    properties: null,
    iconName: "table",
  },
  {
    type: WidgetType.CUSTOM_TABLE,
    name: "Custom Table",
    value: "",
    properties: null,
    iconName: "tableCustom",
  },
  {
    type: WidgetType.SALES_BY_CUSTOMER,
    name: "Sales by Customer Table",
    value: "",
    properties: null,
    iconName: "salesByCustomTable",
  },
  {
    type: WidgetType.AI_COMMENTARY,
    name: "AI Commentary",
    value: "",
    properties: null,
    iconName: "sparkleIcon",
  },
];

export const fontSizeArray = [
  { label: 10, value: "10px" },
  { label: 11, value: "11px" },
  { label: 12, value: "12px" },
  { label: 14, value: "14px" },
  { label: 16, value: "16px" },
  { label: 18, value: "18px" },
];

export const textTools = [
  {
    tooltipMessage: "Bold",
    icon: "bold",
    label: "isBold",
    value: "isBold",
    isBoolean: true,
  },
  {
    tooltipMessage: "Italic",
    icon: "italic",
    label: "isItalic",
    value: "isItalic",
    isBoolean: true,
  },
  {
    tooltipMessage: "Underline",
    icon: "underline",
    label: "isUnderline",
    value: "isUnderline",
    isBoolean: true,
  },
  {
    tooltipMessage: "Align Left",
    icon: "justifyLeft",
    label: "alignment",
    value: "left",
    isBoolean: false,
  },
  {
    tooltipMessage: "Align Center",
    icon: "justify",
    label: "alignment",
    value: "center",
    isBoolean: false,
  },
  {
    tooltipMessage: "Align Right",
    icon: "justifyRight",
    label: "alignment",
    value: "right",
    isBoolean: false,
  },
];

export const titleTools = [
  {
    tooltipMessage: "H1",
    icon: "title",
    iconSize: "4xl",
    label: "fontSize",
    value: FontSize.H1,
    isBoolean: false,
  },
  {
    tooltipMessage: "H2",
    icon: "title",
    iconSize: "xl",
    label: "fontSize",
    value: FontSize.H2,
    isBoolean: false,
  },
  {
    tooltipMessage: "H3",
    icon: "title",
    iconSize: "md",
    label: "fontSize",
    value: FontSize.H3,
    isBoolean: false,
  },
  {
    tooltipMessage: "H4",
    icon: "title",
    iconSize: "xxs",
    label: "fontSize",
    value: FontSize.H4,
    isBoolean: false,
  },
  {
    tooltipMessage: "Align Left",
    icon: "justifyLeft",
    iconSize: "md",
    label: "alignment",
    value: "left",
    isBoolean: false,
  },
  {
    tooltipMessage: "Align Center",
    icon: "justify",
    iconSize: "md",
    label: "alignment",
    value: "center",
    isBoolean: false,
  },
  {
    tooltipMessage: "Align Right",
    icon: "justifyRight",
    iconSize: "md",
    label: "alignment",
    value: "right",
    isBoolean: false,
  },
];

export const alignmentTools = [
  {
    tooltipMessage: "Align Left",
    icon: "justifyLeft",
    iconSize: "md",
    label: "alignment",
    value: "left",
    isBoolean: false,
  },
  {
    tooltipMessage: "Align Center",
    icon: "justify",
    iconSize: "md",
    label: "alignment",
    value: "center",
    isBoolean: false,
  },
  {
    tooltipMessage: "Align Right",
    icon: "justifyRight",
    iconSize: "md",
    label: "alignment",
    value: "right",
    isBoolean: false,
  },
];

export const moveUpTool = {
  tooltipMessage: "Move Up",
  icon: "arrowSmallUp",
};

export const moveDownTool = {
  tooltipMessage: "Move Down",
  icon: "arrowSmallDown",
};

export const removeTool = {
  tooltipMessage: "Remove",
  icon: "delete",
};

export const settingsTool = {
  tooltipMessage: "Edit Settings",
  icon: "config",
};

export const imageTools = [
  {
    tooltipMessage: "Align Left",
    icon: "justifyLeft",
    label: "alignment",
    value: "left",
    isBoolean: false,
  },
  {
    tooltipMessage: "Align Center",
    icon: "justify",
    label: "alignment",
    value: "center",
    isBoolean: false,
  },
  {
    tooltipMessage: "Align Right",
    icon: "justifyRight",
    label: "alignment",
    value: "right",
    isBoolean: false,
  },
];

export const reportKpisOptionIds = {
  standardKpis: "standardKpis",
  customKpis: "customKpis",
  addedKpis: "addedKpis",
};

export const reportKpisOption = [
  {
    title: "report.kpi.standardKpis",
    id: reportKpisOptionIds.standardKpis,
  },
  {
    title: "report.kpi.customKpis",
    id: reportKpisOptionIds.customKpis,
  },
];

export const TRAILING_PERIOD = "trailingPeriod";

export const getYears = (lastNumberOfYears: number) => {
  const currentFullYear = new Date().getFullYear();
  const upcomingYear = currentFullYear + 1;
  const years = [];
  for (let i = upcomingYear; i > upcomingYear - lastNumberOfYears; i--) {
    years.unshift({ title: i });
  }
  return years;
};

export const getMonths = (year: number) => {
  const currentFullYear = new Date().getFullYear();
  let currentMonth = new Date().getMonth();
  let setMonths: Array<{
    title: string;
    value: number;
  }> = [];
  if (year < currentFullYear) {
    setMonths = months;
  } else if (year === currentFullYear) {
    for (let i = currentMonth - 1; i >= 0; i--) {
      setMonths.unshift({ title: moment.months(i), value: i + 1 });
    }
  }

  return setMonths;
};

export const getYearsFilterOptions = () => {
  const lastYearInFilter = 2019;
  const currentFullYear = new Date().getFullYear();
  return getYears(currentFullYear - lastYearInFilter + 1).reverse();
};

export function getAllFiscalYearsSince2019(startMonth: string) {
  const monthNames = months.map((i) => i?.title);
  if (!monthNames.includes(startMonth)) {
    return [];
  }

  // Get the current date
  const currentDate = new Date();

  // Determine the current fiscal year
  const currentYear = currentDate.getFullYear() + 1;
  const currentMonth = currentDate.getMonth() + 1;

  const startMonthIndex = monthNames.indexOf(startMonth) + 1;

  const fiscalYears = Array.from(
    { length: currentYear - 2019 + 1 },
    (_, index) => 2019 + index
  ).filter((year) => {
    if (year === currentYear) {
      return startMonthIndex <= currentMonth;
    }
    return true;
  });

  const formattedFiscalYears = fiscalYears.map((i) => ({ title: i })).reverse();
  return formattedFiscalYears;
}

export function getFiscalYears(startMonthName: string) {
  const currentDate = new Date();

  const startMonth =
    (months.find((month) => month.title === startMonthName)?.value ?? 1) - 1;

  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  if (currentMonth < startMonth || currentMonth - startMonth < 3) {
    return [
      { label: currentYear - 1, value: currentYear - 1 },
      { label: currentYear, value: currentYear },
      { label: currentYear + 1, value: currentYear + 1 },
    ];
  }

  return [
    { label: currentYear, value: currentYear },
    { label: currentYear + 1, value: currentYear + 1 },
  ];
}

export const getYearMonthOptions = (
  show12Months: boolean,
  removeMonthYear: Array<{
    month: number;
    year: number;
  }> = []
) => {
  const yearMonthOptions = [];
  if (show12Months) {
    for (let i = 0; i < 13; i++) {
      const item = moment().subtract(i, "M");
      yearMonthOptions.push({
        year: { title: item.format("YYYY") },
        month: {
          title: item.format("MMMM"),
          value: item.format("M"),
        },
      });
    }
  } else {
    getYearsFilterOptions().forEach((year) => {
      let reversedMonth = [...getMonths(year.title)].reverse();
      reversedMonth.forEach((month) => {
        if (
          removeMonthYear.some(
            (i) => i.month === month.value && i.year === year.title
          )
        )
          return;
        yearMonthOptions.push({
          year: year,
          month: month,
        });
      });
    });
  }
  return yearMonthOptions;
};

export const monthlyReportLabels = {
  quarter: "Quarter",
  month: "Month",
  account: "Account",
  subAccount: "SubAccount",
  calculation: "Calculation",
  cashFlowSection: "CashFlowSection",
  cashFlowSubSection: "CashFlowSubSection",
  parentAccount: "ParentAccount",
  header: "Header",
  aisAccount: "AisAccount",
  period: "Period",
  current: "Current",
  total: "Total",
  customer: "Customer",
  vendor: "Vendor",
  aisNetAccount: "AisNetAccount",
};

export const reportModules = {
  coverPage: "cover-page",
  clientSnapshot: "client-snapshot",
  tripleBottomLine: "triple-bottom-line",
  incomeStatement: "income-statement",
  balanceSheet: "balance-sheet",
  cashFlowStatement: "cash-flow-statement",
  kpis: "kpis",
  annualBusinessCalendar: "annual-business-calendar",
  auxiliaryReportsAppendices: "auxiliary-reports-appendices",
  arAp: "ar-ap",
  dfm: "dfm",
  clientGoals: "client-goals",
  profitRecommendations: "profit-recommendations",
};

export const templateSidebarIds = {
  preview: "Preview",
  config: "Config",
};

export const arApTabs = [
  {
    id: "ar",
    name: "report.arap.accountsReceivable",
  },
  {
    id: "ap",
    name: "report.arap.accountsPayable",
  },
];

export const glSettingsMode = {
  standard: "standard",
  trailing: "trailing",
  customer: "customer",
  agingScheduleAndCustomer: "agingScheduleAndCustomer",
  agingSchedule: "agingSchedule",
  agingScheduleGraph: "agingScheduleGraph",
};

export const glTableConfigurationOptions = [
  { title: "report.note.standardGLTable", type: glSettingsMode.standard },
  { title: "report.note.trailingGLTable", type: glSettingsMode.trailing },
];

export const arNoteTabs = [
  {
    id: "settings",
    name: "report.arap.settings",
  },
  {
    id: "gl",
    name: "report.arap.glTables",
  },
];

export const apNoteTabs = [
  {
    id: "settings",
    name: "report.arap.settings",
  },
  {
    id: "gl",
    name: "report.arap.glTables",
  },
];

export const arApGLTableOptions = [
  {
    title: "report.arap.agingScheduleAndCustomer",
    type: glSettingsMode.agingScheduleAndCustomer,
  },
  { title: "report.arap.agingSchedule", type: glSettingsMode.agingSchedule },
  { title: "report.arap.customer", type: glSettingsMode.customer },
  {
    title: "report.arap.agingScheduleGraph",
    type: glSettingsMode.agingScheduleGraph,
  },
];

export const balanceSheetAssetsItem = [
  "Current Assets",
  "Fixed Assets, Net",
  "Intangible Assets, Net",
  "Goodwill, Net",
  "Right of use Assets, Net",
  "Non-Current Assets",
];
export const balanceSheetLiabilitiesItem = [
  "Current Liabilities",
  "Long-term Liabilities",
  "Owner's Equity",
];

export const glAccountNotes = [
  "Fixed Assets, Net",
  "Intangible Assets, Net",
  "Right of Use Asset, Net",
  "Goodwill, Net",
];

export const glAccountAmortizationNotes = [
  "Intangible Assets, Net",
  "Goodwill, Net",
];

export const shortTermAccounts = [
  {
    id: "55a476fb-6f9f-431b-b958-1d3d640ad339",
    name: "Notes Receivable, Short-Term",
    refAccountId: "2196514e-76a5-4dd5-94d8-e292bd5b6f00",
  },
  {
    id: "c1a4f15d-d84d-48a4-beb0-70e906f225ff",
    name: "Deposits, Short-Term",
    refAccountId: "45c3ba99-2d70-4d4d-a501-41c906f3a0bb",
  },
  {
    id: "d687577f-c6ad-42c5-aa90-4a48b24fcba5",
    name: "Customer Deposits, Short-Term",
    refAccountId: "8ef2d56b-6162-4574-88f6-46b61fddfdc7",
  },
  {
    id: "f4d68711-71b1-4036-94cf-e41a1c61ecf6",
    name: "Deferred Revenue, Short-Term",
    refAccountId: "66bae1de-703a-485d-b866-79b27b850768",
  },
  {
    id: "98851834-35d7-4e79-afc2-a02c2cfb08fb",
    name: "Operating Lease Liability, Short-Term",
    refAccountId: "59c012c4-70ff-4185-afbd-c24c6bcea8d7",
  },
  {
    id: "4b47c416-830e-439e-8c07-538f2cb2e5f6",
    name: "Long-Term Debt, Current Portion",
    refAccountId: "96e9943b-af38-4796-90dd-f05b6f995224",
  },
  {
    id: "f378ea92-52d3-49d3-9b42-b38c4b9af02c",
    name: "Financing Lease Liability, Short-Term",
    refAccountId: "2b9b3598-6c28-4ce3-9bae-858b0947ecbf",
  },
];

export const longTermAccounts = [
  {
    id: "2196514e-76a5-4dd5-94d8-e292bd5b6f00",
    name: "Notes Receivable, Long-Term",
    refAccountId: "55a476fb-6f9f-431b-b958-1d3d640ad339",
  },
  {
    id: "45c3ba99-2d70-4d4d-a501-41c906f3a0bb",
    name: "Deposits, Long-Term",
    refAccountId: "c1a4f15d-d84d-48a4-beb0-70e906f225ff",
  },
  {
    id: "8ef2d56b-6162-4574-88f6-46b61fddfdc7",
    name: "Customer Deposits, Long-Term",
    refAccountId: "d687577f-c6ad-42c5-aa90-4a48b24fcba5",
  },
  {
    id: "66bae1de-703a-485d-b866-79b27b850768",
    name: "Deferred Revenue, Long-Term",
    refAccountId: "f4d68711-71b1-4036-94cf-e41a1c61ecf6",
  },
  {
    id: "59c012c4-70ff-4185-afbd-c24c6bcea8d7",
    name: "Operating Lease Liability, Long-Term",
    refAccountId: "98851834-35d7-4e79-afc2-a02c2cfb08fb",
  },
  {
    id: "96e9943b-af38-4796-90dd-f05b6f995224",
    name: "Long-Term Debt",
    refAccountId: "4b47c416-830e-439e-8c07-538f2cb2e5f6",
  },
  {
    id: "2b9b3598-6c28-4ce3-9bae-858b0947ecbf",
    name: "Financing Lease Liability, Long-Term",
    refAccountId: "f378ea92-52d3-49d3-9b42-b38c4b9af02c",
  },
];

export const customerVendorTableAccountIds = [
  "4540df26-74e4-420f-baa1-37a8bffcc02b", // Accrued Revenue
  "2fbd0ca2-b81c-405c-b5de-59223e3b2915", // Prepaid Expenses
  "c1a4f15d-d84d-48a4-beb0-70e906f225ff", // Deposits, Short-Term
  "45c3ba99-2d70-4d4d-a501-41c906f3a0bb", // Deposits, Long-Term
  "bde522ff-5755-40e9-918d-6d5d34c1f566", // Accrued Expenses
  "f4d68711-71b1-4036-94cf-e41a1c61ecf6", // Deferred Revenue, Short-Term
  "66bae1de-703a-485d-b866-79b27b850768", // Deferred Revenue, Long-Term
  "d687577f-c6ad-42c5-aa90-4a48b24fcba5", // Customer Deposits, Short-Term
  "8ef2d56b-6162-4574-88f6-46b61fddfdc7", // Customer Deposits, Long-Term
];

export const cvTableInputOptions = [
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
];

export const statusIds = {
  aboveTarget: "Above Target",
  withinTarget: "Within Target",
  belowTarget: "Below Target",
  trendingPositive: "Trending (Positive)",
  trendingNegative: "Trending (Negative)",
  inLine: "In Line",
  favorable: "Favorable",
  unfavorable: "Unfavorable",
};

export const companyGoalStatus = [
  { title: "Above Target", value: "Above Target" },
  { title: "Within Target", value: "Within Target" },
  { title: "Below Target", value: "Below Target" },
  { title: "Trending (Positive)", value: "Trending (Positive)" },
  { title: "Trending (Negative)", value: "Trending (Negative)" },
  { title: "In Line", value: "In Line" },
  { title: "Favorable", value: "Favorable" },
  { title: "Unfavorable", value: "Unfavorable" },
];

export const reportPublishStatus = {
  published: "published",
  draft: "draft",
};
export const cvTableInstruction = [
  "report.cvTable.i1",
  "report.cvTable.i2",
  "report.cvTable.i3",
];

export const customerAccounts = [
  "Accrued Revenue",
  "Deferred Revenue - ST",
  "Deferred Revenue - LT",
  "Customer Deposits - ST",
  "Customer Deposits - LT",
];

export const vendorAccounts = [
  "Prepaid Expenses",
  "Deposits - ST",
  "Deposits - LT",
  "Accrued Expenses",
];

export function defaultGlTableOptions() {
  return {
    includeDescription: false,
    includeTotalPercentage: false,
    includeYTD: false,
    includePercentageYTD: false,
    addCustomTable: false,
    tableConfiguration: null,
  };
}

export function defaultCVTableConfig() {
  return {
    addTable: false,
    showTotalPercentage: false,
    customValue: 5,
  };
}

export const tempReportStatus = {
  syncInProgress: "sync-in-progress",
  syncFailed: "sync-failed",
  unmapped: "unmapped",
  completed: "completed",
};

export const syncStatus = {
  reSync: "re-sync",
  mappingComplete: "mapping-complete",
};
