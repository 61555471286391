import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaulTggClient as tggClient } from "@services/AxiosConfig";
import { v4 } from "uuid";

export type ManualBudget = {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  year: number;
};

type Period = {
  month: number;
  budget: number;
  actual: number;
};

type SubAccount = {
  id?: string;
  name: string;
  position?: number;
  periods: Period[];
};

type ParentAccount = {
  id?: string;
  name: string;
  position?: number;
  subAccounts: SubAccount[];
};

export type ManualBudgetVsActuals = {
  budgetInfo: ManualBudget;
  budgetVsActuals: ParentAccount[];
};

export type UpdateDetailsData = {
  details: ParentAccount[];
};

const initialState = {
  createBudget: {
    modalIsOpen: false,
  },
  editBudgetName: {
    modalIsOpen: false,
    budgetId: "",
    budgetName: "",
    saving: false,
    error: null as null | string,
  },
  deleteBudget: {
    modalIsOpen: false,
    budgetId: "",
    saving: false,
  },
  listScreen: {
    loading: true,
    budgets: [] as Array<ManualBudget>,
  },
  viewSelectedBudgetScreen: {
    currentIsSaved: true,
    budgetInfo: null as null | ManualBudget,
    dataHistory: [] as ParentAccount[][],
    loading: true,
    addRowModalIsOpen: false,
    deleteRowModalIsOpen: false,
    selectedCategory: null as number | null,
    selectedSubCategory: null as number | null,
    addBellow: true,
    saving: false,
  },
};

const EMPTY_PERIODS = Array.from(Array(12).keys()).map((i) => ({
  actual: 0,
  budget: 0,
  month: i + 1,
}));

export const fetchManualBudgets = createAsyncThunk(
  "manualBudgets/fetchBudgets",
  async (params: { clientId: string }) => {
    return tggClient.get<
      never,
      {
        items: Array<ManualBudget>;
        currentPageNumber: number;
        recordsPerPage: number;
        totalRecords: number;
        totalPages: number;
      }
    >(`/clients/${params.clientId}/budgets/manual`, {
      params: {
        skip: 0,
        take: 1000,
      },
    });
  }
);

export const fetchManualBudget = createAsyncThunk(
  "manualBudgets/fetchBudget",
  async (params: { clientId: string; budgetId: string }) => {
    return tggClient.get<never, ManualBudgetVsActuals>(
      `/clients/${params.clientId}/budgets/manual/${params.budgetId}`
    );
  }
);

export const createBudget = createAsyncThunk(
  "manualBudgets/create",
  async (params: { clientId: string; budgetName: string; year: number }) => {
    return tggClient.post<never, ManualBudget, { name: string; year: number }>(
      `/clients/${params.clientId}/budgets/manual`,
      { name: params.budgetName, year: params.year }
    );
  }
);

export const updateBudgetName = createAsyncThunk(
  "manualBudgets/updateName",
  async (params: { clientId: string; budgetId: string; name: string }) => {
    return tggClient.patch<never, ManualBudget, { name: string }>(
      `/clients/${params.clientId}/budgets/manual/${params.budgetId}`,
      { name: params.name }
    );
  }
);

export const deleteBudget = createAsyncThunk(
  "manualBudgets/delete",
  async (params: { clientId: string; budgetId: string }) => {
    return tggClient.delete<never, ManualBudget>(
      `/clients/${params.clientId}/budgets/manual/${params.budgetId}`
    );
  }
);

export const updateBudgetDetails = createAsyncThunk(
  "manualBudgets/updateBudgetDetails",
  async (params: {
    clientId: string;
    budgetId: string;
    data: ParentAccount[];
  }) => {
    const details = params.data.map((category, i) => ({
      name: category.name,
      position: i + 1,
      subAccounts: category.subAccounts.map((subCategory, j) => ({
        position: j + 1,
        name: subCategory.name,
        periods: subCategory.periods,
        subAccountId: subCategory.id,
      })),
    }));

    return tggClient.post<never, ManualBudget, UpdateDetailsData>(
      `/clients/${params.clientId}/budgets/manual/${params.budgetId}/details`,
      { details }
    );
  }
);

export const manualBudgetsSlice = createSlice({
  name: "manualBudgets",
  initialState,
  reducers: {
    openCreateBudgetModal: (state) => {
      state.createBudget.modalIsOpen = true;
    },
    closeCreateBudgetModal: (state) => {
      state.createBudget.modalIsOpen = false;
    },
    openDeleteRowModal: (
      state,
      action: PayloadAction<{
        categoryIndex: number;
        subCategoryIndex?: number;
      }>
    ) => {
      state.viewSelectedBudgetScreen.deleteRowModalIsOpen = true;
      state.viewSelectedBudgetScreen.selectedCategory =
        action.payload.categoryIndex;
      state.viewSelectedBudgetScreen.selectedSubCategory =
        action.payload.subCategoryIndex ?? null;
    },
    closeDeleteRowModal: (state) => {
      state.viewSelectedBudgetScreen.deleteRowModalIsOpen = false;
      state.viewSelectedBudgetScreen.selectedSubCategory = null;
      state.viewSelectedBudgetScreen.selectedCategory = null;
    },
    deleteRow: (state) => {
      const data = state.viewSelectedBudgetScreen.dataHistory;
      const current = data[data.length - 1];

      const subCategory = state.viewSelectedBudgetScreen.selectedSubCategory;
      const category = state.viewSelectedBudgetScreen.selectedCategory;

      if (subCategory === null && category !== null) {
        const newValue = [...current];
        newValue.splice(category, 1);
        data.push(newValue);
      } else if (category !== null && subCategory !== null) {
        const newValue = [...current];
        newValue[category] = { ...newValue[category] };
        newValue[category].subAccounts = [...newValue[category].subAccounts];
        newValue[category].subAccounts.splice(subCategory, 1);
        data.push(newValue);
      }
      state.viewSelectedBudgetScreen.currentIsSaved = false;
    },
    openAddRowModal: (
      state,
      action: PayloadAction<{
        categoryIndex: number;
        subCategoryIndex?: number;
        addBellow: boolean;
      }>
    ) => {
      state.viewSelectedBudgetScreen.addRowModalIsOpen = true;
      state.viewSelectedBudgetScreen.selectedCategory =
        action.payload.categoryIndex;
      state.viewSelectedBudgetScreen.selectedSubCategory =
        action.payload.subCategoryIndex ?? null;
      state.viewSelectedBudgetScreen.addBellow = action.payload.addBellow;
    },
    closeAddRowModal: (state) => {
      state.viewSelectedBudgetScreen.addRowModalIsOpen = false;
    },
    addCategory: (state, action: PayloadAction<string>) => {
      const data = state.viewSelectedBudgetScreen.dataHistory;
      const current = data[data.length - 1];

      const selectedCategory = state.viewSelectedBudgetScreen.selectedCategory;
      if (selectedCategory === null) return;
      const newData = [...current];
      newData.splice(
        selectedCategory + (state.viewSelectedBudgetScreen.addBellow ? 1 : 0),
        0,
        { id: v4(), name: action.payload, subAccounts: [] }
      );
      data.push(newData);
      state.viewSelectedBudgetScreen.addRowModalIsOpen = false;
      state.viewSelectedBudgetScreen.currentIsSaved = false;
    },
    addSubCategory: (state, action: PayloadAction<string>) => {
      const data = state.viewSelectedBudgetScreen.dataHistory;
      const current = data[data.length - 1];
      const category = state.viewSelectedBudgetScreen.selectedCategory || 0;
      const subCategoryExists =
        state.viewSelectedBudgetScreen.selectedSubCategory !== null;
      const subCategory =
        state.viewSelectedBudgetScreen.selectedSubCategory || 0;

      const newValue = [...current];
      newValue[category] = { ...newValue[category] };
      newValue[category].subAccounts = [...newValue[category].subAccounts];
      newValue[category].subAccounts.splice(
        subCategoryExists
          ? subCategory + (state.viewSelectedBudgetScreen.addBellow ? 1 : 0)
          : 0,
        0,
        { id: v4(), name: action.payload, periods: EMPTY_PERIODS }
      );
      data.push(newValue);

      state.viewSelectedBudgetScreen.addRowModalIsOpen = false;
      state.viewSelectedBudgetScreen.currentIsSaved = false;
    },
    updateCell: (
      state,
      action: PayloadAction<{
        categoryIndex: number;
        subCategoryIndex: number;
        month: number;
        value?: number;
        isBudget: boolean;
      }>
    ) => {
      const data = state.viewSelectedBudgetScreen.dataHistory;
      const current = data[data.length - 1];
      data.push(
        current.map((category, ci) => ({
          ...category,
          subAccounts: category.subAccounts.map((subCategory, si) => ({
            ...subCategory,
            periods: subCategory.periods.map((period) => ({
              ...period,
              ...(ci === action.payload.categoryIndex &&
                si === action.payload.subCategoryIndex &&
                period.month === action.payload.month && {
                  [action.payload.isBudget ? "budget" : "actual"]:
                    action.payload.value,
                }),
            })),
          })),
        }))
      );
      state.viewSelectedBudgetScreen.currentIsSaved = false;
    },
    updateSubCategoryName: (
      state,
      action: PayloadAction<{
        name: string;
        categoryIndex: number;
        subCategoryIndex: number;
      }>
    ) => {
      const data = state.viewSelectedBudgetScreen.dataHistory;
      const current = data[data.length - 1];
      const category = current[action.payload.categoryIndex];
      const subCategory = category.subAccounts[action.payload.subCategoryIndex];
      const newValue = [...current];
      newValue[action.payload.categoryIndex] = { ...category };
      newValue[action.payload.categoryIndex].subAccounts = [
        ...category.subAccounts,
      ];
      newValue[action.payload.categoryIndex].subAccounts[
        action.payload.subCategoryIndex
      ] = { ...subCategory, name: action.payload.name };
      data.push(newValue);
      state.viewSelectedBudgetScreen.currentIsSaved = false;
    },
    openEditBudgetNameModal: (
      state,
      action: PayloadAction<{
        budgetId: string;
        budgetName: string;
      }>
    ) => {
      state.editBudgetName.modalIsOpen = true;
      state.editBudgetName.budgetId = action.payload.budgetId;
      state.editBudgetName.budgetName = action.payload.budgetName;
    },
    closeEditBudgetNameModal: (state) => {
      if (!state.editBudgetName.saving) {
        state.editBudgetName.modalIsOpen = false;
        state.editBudgetName.budgetName = "";
        state.editBudgetName.error = null;
      }
    },
    setEditBudgetName: (state, action: PayloadAction<string>) => {
      state.editBudgetName.budgetName = action.payload;
    },
    setNameEditError: (state, action: PayloadAction<string>) => {
      state.editBudgetName.error = action.payload;
    },
    openDeleteBudgetModal: (
      state,
      action: PayloadAction<{ budgetId: string }>
    ) => {
      state.deleteBudget.modalIsOpen = true;
      state.deleteBudget.budgetId = action.payload.budgetId;
    },
    closeDeleteBudgetModal: (state) => {
      if (!state.deleteBudget.saving) {
        state.deleteBudget.modalIsOpen = false;
        state.deleteBudget.budgetId = "";
      }
    },
    undo: (state) => {
      const data = state.viewSelectedBudgetScreen.dataHistory;
      if (data.length > 1) {
        data.pop();
        state.viewSelectedBudgetScreen.currentIsSaved = false;
      }
    },
    revert: (state) => {
      state.viewSelectedBudgetScreen.dataHistory = [
        state.viewSelectedBudgetScreen.dataHistory[0],
      ];
      state.viewSelectedBudgetScreen.currentIsSaved = false;
    },
    resetViewBudgetLoading: (state) => {
      state.viewSelectedBudgetScreen.loading = true;
      state.viewSelectedBudgetScreen.budgetInfo = null;
    },
    resetListBudgetsLoading: (state) => {
      state.listScreen.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateBudgetName.pending, (state) => {
      state.editBudgetName.saving = true;
    });
    builder.addCase(updateBudgetName.fulfilled, (state, action) => {
      const budget = state.listScreen.budgets.find(
        (budget) => budget.id === action.payload.id
      );
      if (budget) budget.name = action.payload.name;
    });
    builder.addCase(deleteBudget.pending, (state) => {
      state.deleteBudget.saving = true;
    });
    builder.addCase(deleteBudget.fulfilled, (state) => {
      state.listScreen.budgets = state.listScreen.budgets.filter(
        (budget) => budget.id !== state.deleteBudget.budgetId
      );
    });
    builder.addCase(fetchManualBudgets.pending, (state) => {
      state.listScreen.loading = true;
    });
    builder.addCase(fetchManualBudgets.fulfilled, (state, action) => {
      state.listScreen.budgets = action.payload.items;
      state.listScreen.loading = false;
    });
    builder.addCase(createBudget.fulfilled, (state, action) => {
      state.listScreen.budgets.push(action.payload);
    });
    builder.addCase(fetchManualBudget.pending, (state) => {
      state.viewSelectedBudgetScreen.loading = true;
    });
    builder.addCase(fetchManualBudget.fulfilled, (state, action) => {
      state.viewSelectedBudgetScreen.budgetInfo = action.payload.budgetInfo;
      state.viewSelectedBudgetScreen.dataHistory = [
        action.payload.budgetVsActuals
          .map((category) => ({
            ...category,
            id: v4(),
            subAccounts: category.subAccounts
              .map((subCategory) => ({
                ...subCategory,
                id: v4(),
              }))
              .sort((a, b) => (a.position || 0) - (b.position || 0)),
          }))
          .sort((a, b) => (a.position || 0) - (b.position || 0)),
      ];
      state.viewSelectedBudgetScreen.currentIsSaved = true;
      state.viewSelectedBudgetScreen.loading = false;
    });
    builder.addCase(updateBudgetDetails.pending, (state) => {
      state.viewSelectedBudgetScreen.saving = true;
    });
    builder.addCase(updateBudgetDetails.fulfilled, (state) => {
      state.viewSelectedBudgetScreen.currentIsSaved = true;
    });
    builder.addMatcher(updateBudgetDetails.settled, (state) => {
      state.viewSelectedBudgetScreen.saving = false;
    });
    builder.addMatcher(updateBudgetName.settled, (state) => {
      state.editBudgetName.saving = false;
      state.editBudgetName.modalIsOpen = false;
    });
    builder.addMatcher(deleteBudget.settled, (state) => {
      state.deleteBudget.saving = false;
      state.deleteBudget.modalIsOpen = false;
    });
  },
});

export const manualBudgetsActions = manualBudgetsSlice.actions;
export default manualBudgetsSlice.reducer;
