import common from "./common.json";
import client from "./client.json";
import user from "./user.json";
import profile from "./profile.json";
import report from "./report.json";
import KPIs from "./KPIs.json";
import goals from "./goals.json";
import profitRecommendations from "./profitRecommendations.json";
import settings from "./settings.json";
import document from "./document.json";
import agency from "./agency.json";
import publishingRequirements from "./publishingRequirements.json";
import emailTemplates from "./emailTemplates.json";
import authentication from "./authentication.json";

export default {
  translation: {
    common,
    client,
    user,
    profile,
    report,
    kpis: KPIs,
    goals,
    profitRecommendations,
    settings,
    document,
    agency,
    publishingRequirements,
    emailTemplates,
    authentication,
  },
};
