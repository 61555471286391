import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaulTggClient as tggClient } from "@services/AxiosConfig";

export const EXECUTIVE_SUMMARY_ID = "HUB_AI_EXECUTIVE_SUMMARY";

export enum AI_SETTING_CATEGORY {
  ES = "ES",
  CG = "CG",
  PR = "PR",
  TBL = "TBL",
  NOTES = "NOTES",
  CHARTS = "CHARTS",
}

export type AISetting = {
  optionId: string;
  enabled: boolean;
  description: string;
  displayOrder: number;
  period?: string;
  customOpexAccountId?: string;
};

export type AISettingPayload = {
  optionId?: string;
  settingCategory: AI_SETTING_CATEGORY;
  enabled: boolean;
  displayOrder?: number;
  customOpexAccountId?: string;
};

type AICategoryResponse = {
  settingCategory: AI_SETTING_CATEGORY;
  enabled?: boolean;
  options: AISetting[];
};

const initialState = {
  executiveSummary: [] as AISetting[],
  companyGoals: [] as AISetting[],
  charts: [] as AISetting[],
  profitRecommendationsEnabled: false,
  tripleBottomLineEnabled: false,
  notes: [] as AISetting[],
};

export const fetchAISettings = createAsyncThunk(
  "aiSettings/fetchAISettings",
  async (params: { clientId: string }) => {
    return tggClient.get<never, AICategoryResponse[]>(
      `/clients/${params.clientId}/client-ai-report-settings`
    );
  }
);

export const saveAISettings = createAsyncThunk(
  "aiSettings/saveAISettings",
  async (params: { clientId: string; settings: AISettingPayload[] }) => {
    return tggClient.put<
      never,
      AICategoryResponse[],
      { clientAiReportSettings: AISettingPayload[] }
    >(`/clients/${params.clientId}/client-ai-report-settings/bulk`, {
      clientAiReportSettings: params.settings,
    });
  }
);

export const aiSettingsSlice = createSlice({
  name: "aiSettings",
  initialState,
  reducers: {
    toggleExecutiveSummaryOption(state, action: PayloadAction<string>) {
      const option = state.executiveSummary.find(
        (option) => option.optionId === action.payload
      );
      if (option) option.enabled = !option.enabled;
    },
    toggleGoalOption(state, action: PayloadAction<string>) {
      const option = state.companyGoals.find(
        (option) => option.optionId === action.payload
      );
      if (option) option.enabled = !option.enabled;
    },
    toggleEnableAllGoals(state) {
      if (state.companyGoals.every((option) => option.enabled)) {
        state.companyGoals.forEach((option) => {
          option.enabled = false;
        });
      } else {
        state.companyGoals.forEach((option) => {
          option.enabled = true;
        });
      }
    },
    toggleEnableAllCharts(state) {
      if (state.charts.every((option) => option.enabled)) {
        state.charts.forEach((option) => {
          option.enabled = false;
        });
      } else {
        state.charts.forEach((option) => {
          option.enabled = true;
        });
      }
    },
    toggleChartOption(state, action: PayloadAction<string>) {
      const option = state.charts.find(
        (option) => option.optionId === action.payload
      );
      if (option) option.enabled = !option.enabled;
    },
    toggleEnableAllNotes(state) {
      if (state.notes.every((option) => option.enabled)) {
        state.notes.forEach((option) => {
          option.enabled = false;
        });
      } else {
        state.notes.forEach((option) => {
          option.enabled = true;
        });
      }
    },
    toggleNoteOption(state, action: PayloadAction<string>) {
      const option = state.notes.find(
        (option) => option.optionId === action.payload
          || `${option.optionId}-${option.customOpexAccountId}` === action.payload
      );
      if (option) option.enabled = !option.enabled;
    },
    toggleProfitRecommendations(state) {
      state.profitRecommendationsEnabled = !state.profitRecommendationsEnabled;
    },
    toggleTripleBottomLine(state) {
      state.tripleBottomLineEnabled = !state.tripleBottomLineEnabled;
    },
    toggleEnableAll(state) {
      const executiveSummaryOption = state.executiveSummary.find(
        (option) => option.optionId === EXECUTIVE_SUMMARY_ID
      );
      const otherESOptions = state.executiveSummary.filter(
        (option) => option.optionId !== EXECUTIVE_SUMMARY_ID
      );

      const isAllChecked =
        (executiveSummaryOption?.enabled ||
          otherESOptions.every((o) => o.enabled)) &&
        state.companyGoals.every((o) => o.enabled) &&
        state.profitRecommendationsEnabled &&
        state.tripleBottomLineEnabled &&
        state.charts.every((o) => o.enabled) &&
        state.notes.every((o) => o.enabled);

      if (isAllChecked) {
        state.executiveSummary.forEach((option) => {
          option.enabled = false;
        });
        state.companyGoals.forEach((option) => {
          option.enabled = false;
        });
        state.profitRecommendationsEnabled = false;
        state.tripleBottomLineEnabled = false;
        state.charts.forEach((option) => {
          option.enabled = false;
        });
        state.notes.forEach((option) => {
          option.enabled = false;
        });
      } else {
        if (
          state.executiveSummary.some(
            (option) =>
              option.optionId !== EXECUTIVE_SUMMARY_ID && option.enabled
          )
        ) {
          state.executiveSummary.forEach((option) => {
            if (option.optionId !== EXECUTIVE_SUMMARY_ID) option.enabled = true;
          });
        } else if (executiveSummaryOption) {
          executiveSummaryOption.enabled = true;
        }
        state.companyGoals.forEach((option) => {
          option.enabled = true;
        });
        state.profitRecommendationsEnabled = true;
        state.tripleBottomLineEnabled = true;
        state.charts.forEach((option) => {
          option.enabled = true;
        });
        state.notes.forEach((option) => {
          option.enabled = true;
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAISettings.fulfilled, (state, action) => {
      state.executiveSummary =
        action.payload.find(
          (category) => category.settingCategory === AI_SETTING_CATEGORY.ES
        )?.options || [];

      state.companyGoals =
        action.payload.find(
          (category) => category.settingCategory === AI_SETTING_CATEGORY.CG
        )?.options || [];

      state.profitRecommendationsEnabled =
        action.payload.find(
          (category) => category.settingCategory === AI_SETTING_CATEGORY.PR
        )?.enabled || false;

      state.tripleBottomLineEnabled =
        action.payload.find(
          (category) => category.settingCategory === AI_SETTING_CATEGORY.TBL
        )?.enabled || false;

      state.charts =
        action.payload.find(
          (category) => category.settingCategory === AI_SETTING_CATEGORY.CHARTS
        )?.options || [];

      state.notes =
        action.payload.find(
          (category) => category.settingCategory === AI_SETTING_CATEGORY.NOTES
        )?.options || [];
    });
  },
});

export const aiSettingsActions = aiSettingsSlice.actions;
